$font-size: calc(0.8rem + 0.2vw) !default
$font-weight: 400 !default
$font-family: "PT Sans", -apple-system, "Helvetica Neue", "Segoe UI", "Roboto", sans-serif !default
$heading-weight: 600 !default
$line-height: 1.6 !default
$link-color: #68f !default
$dark: #212228 !default
$light: #ffffff !default

@function reduce ($percent)
  @return rgba(mix($dark, $light), calc($percent / 100))
